import styled from "styled-components"

export default styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
  
  @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
    
    thead, tbody, th, td, tr { 
      display: block;
      width: calc(100% + 20px);
      margin-left: -10px;
    }
  
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    td { 
      border: none;
      position: relative;
      padding-left: 50%; 
    }
  
    td:before { 
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
    }
    
    td::before {
      display: block;
      padding: 1.4rem;
    }
  
    td:nth-of-type(1)::before { content: "Język:"; }
    td:nth-of-type(2)::before { content: "Wiek:"; }
    td:nth-of-type(3)::before { content: "Termin:"; }
    td:nth-of-type(4)::before { content: "Cena:"; }
    td:nth-of-type(5)::before { content: "Nauczyciel:"; }
    td:nth-of-type(6)::before { content: " "; }
  }
`;