import Advice from "../atoms/table-advice"
import AdviceContent from "../atoms/table-advice-content"
import ActionLink from "../atoms/action-paragraph-link"
import React from "react"

export default () => (
  <>
    <Advice>Nie znalazłeś odpowiedniego kursu dla siebie lub swojego dziecka?</Advice>
    <AdviceContent>Prosimy wypełnić <ActionLink to={"/kursy/zapisy"} title={"Formularz zapisu do kursu"}>formularz
      zgłoszeniowy</ActionLink>. Oddzwonimy do ciebie z naszą propozycją!
      <br />
      <br />
      * Za okres ferii zimowych połowa ceny za miesiąc.
    </AdviceContent>
  </>
)