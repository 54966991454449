import React, { Component } from "react"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heading from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"
import api, { endpoint } from "../../api"
import Table from "../molecules/table-offer"
import TableWrap from "../atoms/table"
import Advice from "../molecules/advice"
import Loading from "../molecules/loading"
import TableHead from "../atoms/table-head"
import TableTr from "../atoms/table-tr"
import TableTh from "../atoms/table-th"
import TableBody from "../atoms/table-body"
import styled from "styled-components"
import pricesPdf from "../../docs/kursy_rok_23_24.pdf"
import { normalLink as ActionParagraphAnchor } from "../atoms/action-paragraph-link"

const CategorySection = styled.section`
  margin-top: 12vh;
`

const CategoryHeader = styled.h6`
  font-size: 2.5rem;
  margin: 0 0 4vh;
  border-left: 3px solid ${({ theme }) => theme.pink};
  padding-left: 10px;
`

class OfferPage extends Component {
  state = {
    loading: true,
    error: "",
    data: {},
    headers: {},
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    api(endpoint.offerItems)
      .then(data => {
        data = data.data.content.rendered
        const pattern = /<p>(.*?)<\/p>/g
        const result = {}
        let headers = {}
        data.match(pattern).forEach((element, index) => {
          if (index === 0) {
            headers = this.formatData(element)
          } else {
            const formatedData = this.formatData(element)
            if (!Array.isArray(result[formatedData.cat])) {
              result[formatedData.cat] = []
            }
            result[formatedData.cat].push(formatedData)
          }
        })
        this.setState({ data: result, loading: false, headers })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }

  formatData = item => {
    return JSON.parse(
      item.substring(3, item.length - 4).replace(/&#(\d+);/g, () => {
        return '"'
      })
    )
  }

  embedTable = key => {
    if (this.state.loading) {
      return (
        <tr>
          <td colSpan={6} rowSpan={3}>
            <Loading />
          </td>
        </tr>
      )
    }

    if (this.state.error) {
      return (
        <tr>
          <td colSpan={6} rowSpan={3}>
            <p style={{ marginTop: "5vh" }}>
              Nie mogliśmy wczytać naszych kursów. Prosimy o powrót za chwilę.
            </p>
          </td>
        </tr>
      )
    }

    return <Table data={this.state.data[key]} />
  }

  render = () => (
    <Wrapper>
      <ParagraphItalic>nasze-kursy</ParagraphItalic>
      <Heading>Lista dostępnych kursów</Heading>
      <br />
      <br />
      <h5>Kurs angielskiego</h5>
      <p>
        Wykorzystujemy autorskie programy nauczania prezentujące najwyższy
        poziom merytoryczny. Stawiamy sobie za cel metodyczne, konsekwentne i
        skuteczne pogłębianie wiedzy językowej naszych słuchaczy, a w rezultacie
        osiągnięcie przez nich założonego poziomu biegłości językowej.
        Szczególnie obciążonym obowiązkami polecamy  kursy odbywające się raz w
        tygodniu.
      </p>
      <p>
        Do kogo skierowany jest kurs z języka angielskiego?
        <ul>
          <li>Dla tych, którzy chcą pewnie mówić po angielsku.</li>
          <li>
            Osoby, których umiejętności czytania/pisania/gramatyki są lepsze niż
            mówienie i rozumienie.
          </li>
          <li>
            Ci, którzy chcą skrócić „czas na myślenie” i mówić lub reagować
            szybciej.
          </li>
          <li>
            Ci, którzy potrzebują języka angielskiego, aby poprawić możliwości
            zatrudnienia lub podróżować i poznawać nowych ludzi.
          </li>
          <li>Dla tych, którzy chcą go używać w pracy.</li>
        </ul>
      </p>
      <b>
        Lista dostępnych kursów na rok szkolny 2023/2024 wraz z cennikiem
        znajduje się w przygotowanej{" "}
        <ActionParagraphAnchor
          href={pricesPdf}
          target="_blank"
          title={"Kursy rok 2023/2024 i cennik"}
        >
          broszurze (pobierz)
        </ActionParagraphAnchor>
        .
      </b>
      {Object.keys(this.state.headers).map(keys => {
        return (
          <CategorySection key={keys}>
            <CategoryHeader>{this.state.headers[keys]}</CategoryHeader>
            <TableWrap>
              <TableHead>
                <TableTr>
                  <TableTh>Nazwa</TableTh>
                  <TableTh>Wiek</TableTh>
                  <TableTh>Minut / tydzień</TableTh>
                  <TableTh>Opłata miesięczna *</TableTh>
                </TableTr>
              </TableHead>
              <TableBody>{this.embedTable(keys)}</TableBody>
            </TableWrap>
          </CategorySection>
        )
      })}
      <Advice />
    </Wrapper>
  )
}

export default OfferPage
