import TableTr from "../atoms/table-tr"
import TableTd from "../atoms/table-td"
import TableBtn from "../atoms/table-button"
import React from "react"

export default ({ data }) => {
  if (!data) return null;
  return (
    <>
      {data.map((item, index) => {
        return (
          <TableTr key={index}>
            <TableTd>{item.lang}</TableTd>
            <TableTd>{item.age}</TableTd>
            <TableTd>{item.time}</TableTd>
            <TableTd>{item.price}</TableTd>
            <TableTd>
              <TableBtn
                to={"/kursy/zapisy"}
                title={"Formularz zapisu do kursu"}
                state={{ additional: `Kurs: ${item.lang}, ${item.time}, ${item.age}` }}
              >
                zapisz się
              </TableBtn>
            </TableTd>
          </TableTr>
        )
      })}
    </>
  )
}
