import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import PageBackground from "../components/atoms/page-background"
import OfferPage from "../components/organisms/kurs-page"
import { graphql } from "gatsby"

const OfferPageWrapper = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="Nasze kursy" />
    <PageBackground img={data.placeholderImage.childImageSharp.fluid} />
    <OfferPage />
  </Layout>
)

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "abc-alphabet-blackboard.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 3872) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default OfferPageWrapper;
