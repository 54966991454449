import styled from "styled-components"
import { Link } from "gatsby"

export default styled(Link)`
  text-decoration: none;
  color: white;
  background: #2996B3;
  padding: 1vh 2vw; 
  transition: all 100ms linear;
  white-space: nowrap;
  
  :hover {
    background: #006883;
    color: white;
  }
`